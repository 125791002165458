.pdf-container {
  display: flex;
  justify-content: center;
  user-select: none;
}

.controls {
  margin: 20px;
}

@media print {
  .pdf-container {
    display: none;
  }
}
